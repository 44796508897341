import React from 'react';
import ReactHTMLParser from 'react-html-parser';

import Slider from '@latitude/slider';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import './carosuelHeader.css';

const REFRESH_INTERVAL_MILLIS = 5 * 1000; // Time to display each header (milliseconds)


export function itemLayout(item, key) {
  return (
    <div key={key}>
      <div className="pageHeaderItem">
        <div className="pageHeaderItemText">
          <h1>{ReactHTMLParser(item.title)}</h1>
          <div className="header-text">{ReactHTMLParser(item.text)}</div>
          <div className="header-buttons">
            {item.urlFindOutMore && (
              <Link
                className="lfs-find-out-more"
                button={BUTTON_STYLE.SECONDARY_INVERSE}
                target="_blank"
                rel="noopener noreferrer"
                href={item.urlFindOutMore}
              >
                {item.labelFindOutMore
                  ? item.labelFindOutMore
                  : 'Find out more'}
              </Link>
            )}
            <Link
              button={BUTTON_STYLE.PRIMARY}
              target="_blank"
              rel="noopener noreferrer"
              trackId= 'hero-apply-now'
              href={
                item.urlApplyNow
                  ? item.urlApplyNow
                  : 'https://cardapp.latitudefinancial.com/apply/gem/?cardType=gemvisaau'
              }
            >
              Apply now
            </Link>
          </div>
          <div className="header-terms">{ReactHTMLParser(item.termsconds)}</div>
        </div>
        <div className="pageHeaderItemImage">
          <img src={item.headerImage} alt={ReactHTMLParser(item.title)} />
        </div>
      </div>
    </div>
  );
}

function CarouselHeader(props) {
  return (
    <div className="pageHeader">
      <Slider
        className="lfs-carousel-pageheader"
        slidesToShow={1}
        slidesToScroll={1}
        infinite={true}
        autoplay={true}
        autoplaySpeed={REFRESH_INTERVAL_MILLIS}
        pauseOnHover={true}
      >
        {props.HEADER_ITEMS.map(itemLayout)}
      </Slider>
    </div>
  );
}

export default CarouselHeader;
