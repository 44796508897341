import React from 'react';
import './_typography.scss';

export function TypographyBase(props) {
  const { className, as, ...componentProps } = props;
  const Component = as || 'div';

  return (
    <Component
      className={`typography typography__${as} ${className || ''}`}
      {...componentProps}
    />
  );
}

function H1(props) {
  return <TypographyBase as="h1" {...props} />;
}

function H2(props) {
  return <TypographyBase as="h2" {...props} />;
}

function H3(props) {
  return <TypographyBase as="h3" {...props} />;
}

function H4(props) {
  return <TypographyBase as="h4" {...props} />;
}

function H5(props) {
  return <TypographyBase as="h5" {...props} />;
}

function H6(props) {
  return <TypographyBase as="h6" {...props} />;
}

function BODY(props) {
  return (
    <TypographyBase
      as="p"
      className={`${props.className || ''} typography__p--body`}
      {...props}
    />
  );
}

function SUBTITLE(props) {
  return (
    <TypographyBase
      as="p"
      className={`${props.className || ''} typography__p--subtitle`}
      {...props}
    />
  );
}

function LEGAL(props) {
  return (
    <TypographyBase
      as="p"
      className={`${props.className || ''} typography__p--legal`}
      {...props}
    />
  );
}

export const Typography = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  BODY,
  SUBTITLE,
  LEGAL
};
