import React from 'react';
import PaymentOptionsComponent, {
  PaymentOptionButtons,
  PaymentOptionCallout,
  PaymentOptionDescription,
  PaymentOptionHeading,
  PaymentOptionIcon,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionNote,
  PaymentOptionTitle,
  PaymentOptionsColumn
} from '@latitude/payment-options';
import SvgInline from '@latitude/svg-inline';
import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  MARGIN
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Heading4 } from '@latitude/heading';
import { Horizontal } from '@latitude/spacing';
import { Strong, Text } from '@latitude/text';
import { Tel } from '@latitude/tel';
import imgPostShop from '../../images/partner-logos/postshop.png';

const PaymentOptions = () => (
  <Box
    id="payment-options"
    css={`
      background-color: ${COLOR.WHITE};
      @media (min-width: ${BREAKPOINT.LG}) {
        background-color: ${COLOR.BLUE_SKY};
      }
    `}
  >
    <Box.Section backgroundColor={COLOR.TRANSPARENT}>
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
        Payment Options
      </Heading4>
      <PaymentOptionsComponent
        data={[
          {
            id: 'internet-banking',
            title: 'Internet Banking',
            titleHtml: 'Internet Banking',
            content: (
              <>
                <PaymentOptionsColumn>
                  <PaymentOptionIcon>
                    <SvgInline name="payment-option-online" />
                  </PaymentOptionIcon>
                  <PaymentOptionTitle>Internet Banking</PaymentOptionTitle>
                  <PaymentOptionDescription>
                    Gem Visa is loaded as a bill payee with most major banks.
                  </PaymentOptionDescription>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <Text fontSize="14px">
                    Select Gem Visa from your bill payments options and follow
                    the instructions to set up regular payments.
                  </Text>
                  <br />
                  <PaymentOptionHeading>
                    The information you&apos;ll need to include is:
                  </PaymentOptionHeading>
                  <PaymentOptionList>
                    <PaymentOptionListItem>
                      <Text fontSize="14px">
                        Payee: Gem Visa &amp; Bank Account number: 03 0502
                        0681686 00.
                      </Text>
                    </PaymentOptionListItem>
                    <PaymentOptionListItem>
                      <Text fontSize="14px">
                        Reference: 00 and then the last 10 digits of your
                        account number.
                        <br />
                        <br />
                        You can find your account number in the top right hand
                        corner of your Gem Visa statement.
                      </Text>
                    </PaymentOptionListItem>
                    <PaymentOptionListItem>
                      <Text fontSize="14px">Code: 0000-0060-1073</Text>
                    </PaymentOptionListItem>
                    <PaymentOptionListItem>
                      <Text fontSize="14px">
                        Particulars: Your surname &amp; first initial
                      </Text>
                    </PaymentOptionListItem>
                  </PaymentOptionList>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionCallout>
                    <Strong>No Fees</Strong>
                    <Text fontSize="14px">
                      We do not charge a fee for payments made via Internet
                      Banking.
                    </Text>
                  </PaymentOptionCallout>
                </PaymentOptionsColumn>
              </>
            )
          },
          {
            id: 'direct-debit',
            title: 'Direct Debit',
            titleHtml: 'Direct Debit',
            content: (
              <>
                <PaymentOptionsColumn>
                  <PaymentOptionIcon>
                    <SvgInline name="payment-option-direct-debit" />
                  </PaymentOptionIcon>
                  <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                  <PaymentOptionDescription>
                    Easily make recurring repayments.
                  </PaymentOptionDescription>
                  <PaymentOptionButtons>
                    <Horizontal spacing={MARGIN.M8}>
                      <Link
                        button={BUTTON_STYLE.SECONDARY}
                        href="tel:0800500505"
                        trackId="payment-options-direct-debit-call-link"
                        trackEventData={{
                          location: 'Payment Options'
                        }}
                        width="156px"
                      >
                        Call us
                      </Link>
                      <Link
                        button={BUTTON_STYLE.TERTIARY}
                        target="_blank"
                        rel="noopener notreferrer"
                        href="https://assets.latitudefinancial.com/forms/cards/direct-debit/GemVisa_Direct_Debit.pdf"
                        trackId="payment-options-direct-debit-download-link"
                        trackEventData={{
                          location: 'Payment Options'
                        }}
                        width="156px"
                      >
                        Download form
                      </Link>
                    </Horizontal>
                  </PaymentOptionButtons>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <Text fontSize="14px">
                    The direct debit facility helps to ensure you never miss a
                    payment by setting up regular monthly payments to your Gem
                    Visa account from your bank&apos;s savings or cheque
                    account.
                  </Text>
                  <br />
                  <Text fontSize="14px">
                    Direct debit is best when you would like to make monthly
                    payments which are based on a selection of the following:
                    minimum monthly payment, a fixed monthly amount* or payment
                    to reduce further interest.
                  </Text>
                  <br />
                  <Text fontSize="14px">
                    Payments may vary each month based on your payment
                    selection.
                  </Text>
                  <br />
                  <PaymentOptionHeading>Call us</PaymentOptionHeading>
                  <Text fontSize="14px">
                    You can call us on <Tel no="0800 500 505" /> to set up your
                    Direct Debit over the phone.
                  </Text>
                  <br />
                  <PaymentOptionHeading>Download form</PaymentOptionHeading>
                  <Text fontSize="14px">
                    Download a Direct Debit request form{' '}
                    <a
                      href="https://assets.latitudefinancial.com/forms/cards/direct-debit/GemVisa_Direct_Debit.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    . Once you&apos;ve completed your form you can email it{' '}
                    <a href="mailto:geminfo@gemfinance.co.nz">here</a>.
                  </Text>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionCallout>
                    <Strong>No Fees</Strong>
                    <Text fontSize="14px">
                      We do not charge a fee for payments made via Direct Debit.
                    </Text>
                  </PaymentOptionCallout>
                  <PaymentOptionNote>
                    <br />
                    <Text fontSize="14px">
                      <Strong>Note:</Strong> *Fixed monthly amount in this
                      instance is the greater of a fixed monthly amount or
                      minimum monthly payment as shown on your Gem Visa
                      statement.
                    </Text>
                  </PaymentOptionNote>
                </PaymentOptionsColumn>
              </>
            )
          },
          {
            id: 'automatic-payment',
            title: 'Automatic Payment',
            titleHtml: 'Automatic Payment',
            content: (
              <>
                <PaymentOptionsColumn>
                  <PaymentOptionIcon>
                    <SvgInline name="icon-easy" />
                  </PaymentOptionIcon>
                  <PaymentOptionTitle>Automatic Payment</PaymentOptionTitle>
                  <PaymentOptionDescription>
                    One of the quickest &amp; easiest way to make repayments.
                  </PaymentOptionDescription>
                  <PaymentOptionButtons>
                    <Horizontal spacing={MARGIN.M8}>
                      <Link
                        button={BUTTON_STYLE.TERTIARY}
                        target="_blank"
                        href="https://assets.latitudefinancial.com/forms/cards/auto-payment/gemvisanz-ap.pdf"
                        rel="noopener notreferrer"
                        trackId="payment-options-automatic-payment-link"
                        trackEventData={{
                          location:
                            'Check your Personal Loan rate in just 3 minutes.'
                        }}
                        width="300px"
                      >
                        Download form
                      </Link>
                    </Horizontal>
                  </PaymentOptionButtons>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <Text fontSize="14px">
                    The automatic payment facility helps to ensure you never
                    miss a payment by setting up automatic monthly payments to
                    your Gem Visa account from your bank savings or cheque
                    account.
                  </Text>
                  <br />
                  <Text fontSize="14px">
                    Use an automatic payment when you would like to make regular
                    payments that are set and controlled by you. You choose to
                    pay the same amount and how frequently you pay: weekly,
                    fortnightly or monthly.
                  </Text>
                  <br />
                  <PaymentOptionHeading>Download form</PaymentOptionHeading>
                  <Text fontSize="14px">
                    Download an automatic payment form{' '}
                    <a
                      href="https://assets.latitudefinancial.com/forms/cards/auto-payment/gemvisanz-ap.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{' '}
                    or you can set up automatic payments directly with your
                    bank.
                  </Text>
                  <br />
                  <Text fontSize="14px">
                    Once you&apos;ve completed your form you can take it to your
                    bank to organise or email it to Gem{' '}
                    <a href="mailto:geminfo@gemfinance.co.nz">here</a>.
                  </Text>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionCallout>
                    <Strong>Fees</Strong>
                    <Text fontSize="14px">
                      Fees may apply this will depend on your banking provider.
                    </Text>
                  </PaymentOptionCallout>
                </PaymentOptionsColumn>
              </>
            )
          },
          {
            id: 'postshop',
            title: 'PostShop',
            titleHtml: 'PostShop',
            content: (
              <>
                <PaymentOptionsColumn>
                  <PaymentOptionIcon>
                    <img
                      src={imgPostShop}
                      height="75px"
                      padding-bottom="15px"
                      alt="PostShop"
                    />
                  </PaymentOptionIcon>
                  <PaymentOptionTitle>PostShop</PaymentOptionTitle>
                  <PaymentOptionDescription>
                    Simply take your statement and pay at a PostShop branch.
                  </PaymentOptionDescription>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionCallout>
                    <Strong>Fees</Strong>
                    <Text fontSize="14px">
                      A payment handling fee of $2.70 applies to each payment.
                    </Text>
                  </PaymentOptionCallout>
                </PaymentOptionsColumn>
              </>
            )
          }
        ]}
        tabContentMinHeight="385px"
      />
    </Box.Section>
  </Box>
);

export default PaymentOptions;
