import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography } from '../../Typography';

const StandaloneTextBody = ({ text, style }) => {
  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        if (style === 'Subtitle') {
          return <Typography.SUBTITLE>{children}</Typography.SUBTITLE>;
        } else if (style === 'Legal') {
          return <Typography.LEGAL>{children}</Typography.LEGAL>;
        }
        return <Typography.BODY>{children}</Typography.BODY>;
      }
    }
  };
  return <>{documentToReactComponents(text, OPTIONS)}</>;
};

export default StandaloneTextBody;
